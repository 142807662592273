.requisites {
  display: flex;
  flex-direction: column; }
  .requisites__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start; }
  .requisites__icon {
    width: 150px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .requisites__title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20pt; }
  .requisites__content {
    display: flex;
    align-items: center;
    justify-content: flex-start; }
